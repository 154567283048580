import request from '@/utils/request';

// 社区配置
export const getCommunityConfig = () => request.get(`study/community/setting/detail`)

// 社区 全部人员查询
export const getAllMember = params => request.post(`study/community/subject/member`, params)

// 社区 我的消息数
export const getMsgCount = params => request.post(`study/community/subject/information`, params)


// 圈子 全部列表
export const getAllGroupList = params => request.post(`study/community/group/list`, params)

// 圈子 我的/发现列表
export const getGroupList = params => request.post(`study/community/group/discovery`, params)

// 圈子 详情
export const getGroupDeatil = id => request.get(`study/community/group/${id}/detail`)

// 圈子 新增编辑
export const postGroup = params => request.post(`study/community/group/submit`, params)

// 圈子 人员列表
export const getGroupMembersList = (id, params) => request.post(`study/community/group/${id}/members`, params)

// 圈子 移除人员
export const groupMembersDel = (id, params) => request.post(`study/community/group/${id}/member/batchdel`, params)

// 圈子 设置管理员
export const groupSetAdmin = (id, params) => request.post(`study/community/group/${id}/member/setadmin`, params)

// 圈子 取消管理员
export const groupDelAdmin = (id, params) => request.post(`study/community/group/${id}/member/deladmin`, params)

// 圈子 申请加入
export const joinGroup = (params) => request.post(`study/community/group/member/join`, params)

// 圈子 同意加入
export const agreeJoinGroup = (params) => request.post(`study/community/group/member/invite/join`, params)

// 圈子 邀请成员信息
export const groupInviteJoinInfo = (params) => request.post(`study/community/group/member/invite/join/message`, params)

// 圈子 邀请成员校验
export const groupInviteJoinCheck = (params) => request.post(`study/community/group/invite`, params)

// 圈子 邀请成员提交
export const groupInviteJoin = (params) => request.post(`study/community/group/invite/member`, params)

// 圈子 成员审核
export const auditGroupMember = (params) => request.post(`study/community/group/member/audit`, params)

// 圈子 退出
export const quitGroup = (params) => request.post(`study/community/group/member/quit`, params)

// 圈子 解散
export const dissolveGroup = (params) => request.post(`study/community/group/dissolve`, params)


// 话题 列表
export const getTopicList = params => request.post(`study/community/topic/list`, params)

// 话题 详情
export const getTopicDeatil = id => request.get(`study/community/topic/${id}/detail`)

// 话题 新增
export const postTopic = params => request.post(`study/community/topic/submit`, params)

// 话题 关注
export const followTopic = params => request.post(`study/community/topic/follow`, params)

// 话题 最佳分享者
export const contributeTopic = params => request.post(`study/community/topic/contribute`, params)


// 链接 解析
export const linkParsing = params => request.post(`study/community/subject/link`, params)


// 主题 列表
export const getSubjectList = params => request.post(`study/community/subject/list`, params)

// 主题 置顶列表
export const getTopSubjectList = params => request.post(`study/community/subject/home/top`, params)

// 主题 详情
export const getSubjectDeatil = params => request.post(`study/community/subject/detail`, params)

// 主题 发布
export const postSubject = params => request.post(`study/community/subject/submit`, params)

// 主题 草稿发布
export const publishSubject = params => request.post(`study/community/subject/publish`, params)

// 主题 删除
export const delSubject = params => request.post(`study/community/subject/del`, params)

// 主题 收藏
export const collectSubject = params => request.post(`study/community/subject/collect`, params)

// 主题 关注
export const followSubject = params => request.post(`study/community/subject/follow`, params)

// 主题 点赞
export const likeSubject = params => request.post(`study/community/subject/like`, params)

// 主题 置顶
export const topSubject = params => request.post(`study/community/subject/globaltop`, params)

// 主题 加精
export const condensedSubject = params => request.post(`study/community/subject/condensed`, params)

// 主题 问题关闭
export const closeSubject = params => request.post(`study/community/subject/qa/close`, params)


// 评论 列表
export const getCommentList = params => request.post(`study/community/comment/list`, params)

// 评论 提交
export const submitComment = params => request.post(`study/community/comment/submit`, params)

// 评论 点赞
export const likeComment = params => request.post(`study/community/comment/like`, params)

// 评论 删除
export const delComment = params => request.post(`study/community/comment/del`, params)

// 评论 采纳
export const solveComment = params => request.post(`study/community/subject/qa/solve`, params)

// 评论 推荐
export const recommendComment = params => request.post(`study/community/subject/qa/recommend`, params)


// 我的 关注的
export const getMyFollowList = params => request.post(`study/community/subject/follow/me`, params)

// 我的 收藏的
export const getMyCollectList = params => request.post(`study/community/subject/collect/me`, params)

// 我的 评论/回答我的
export const getCommentForMeList = params => request.post(`study/community/subject/comment/me`, params)

// 我的 点赞我的
export const getLikeForMeList = params => request.post(`study/community/subject/like/me`, params)

// 我的 邀请我的
export const getInviteForMeList = params => request.post(`study/community/subject/remind/me`, params)