<template>
  <div class="side-analysis">
    <div class="side-tit">
      <div class="side-tit-left">{{ $t('bbs.best_sharer') }}</div>
      <!-- 最佳分享者 -->
    </div>
    <a-spin :spinning="loading">
      <div class="side-analysis-list">
        <div class="side-analysis-item" v-for="item in list" :key="item.userId">
          <div class="avatar">
            <a-avatar
              :size="40"
              :src="item.portrait || require(`@/assets/image/bbs/avatar.png`)"
            >
              <OpenData type="userName" :openid="item.realName" />
            </a-avatar>
          </div>
          <div class="text">
            <div class="top">
              <span class="name">
                <OpenData type="userName" :openid="item.realName" />
              </span>
            </div>
            <div class="bottom">
              <span class="department">
                <OpenData type="departmentName" :openid="item.departmentName" />
              </span>
              <span>{{ item.nums }} {{ $t('bbs.topic') }}</span>
              <!-- 主题 -->
            </div>
          </div>
        </div>
      </div>
      <a-empty v-if="!list.length" />
    </a-spin>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { contributeTopic } from "@/api/bbs";
import OpenData from "@/components/OpenData.vue";
export default {
  name: "sideAnalysis",
  components: {
    OpenData,
  },
  props: {
    topicId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      loading: true,
      list: [],
    });

    contributeTopic({ id: props.topicId }).then((res) => {
      state.loading = false;
      state.list = res.data || [];
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.side-tit {
  padding: 15px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  line-height: 24px;
  .mixinFlex(space-between);
  &-left {
    font-size: 16px;
    color: #202020;
  }
  &-right {
    a {
      font-size: 14px;
      color: #666666;
      transition: color 0.3s;
      &:hover {
        color: @color-theme;
      }
    }
  }
}
.side-analysis {
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  &-list {
    padding: 15px 20px;
  }
  &-item {
    margin-bottom: 15px;
    .mixinFlex(space-between; center);
    &:last-child {
      margin-bottom: 0;
    }
    .avatar {
      width: 40px;
      height: 40px;
    }
    .text {
      width: calc(100% - 48px);
      .top {
        font-size: 14px;
        .name {
          color: #333;
          margin-right: 5px;
          max-width: 150px;
          .mixinEllipsis(22px);
        }
      }
      .bottom {
        .mixinFlex(space-between; center);
        font-size: 12px;
        color: #666;
        .department {
          max-width: 100px;
          .mixinEllipsis(20px);
        }
      }
    }
  }
}
</style>
